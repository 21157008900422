.taskSide-detail-closed {
    position: fixed;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    color: black;
    right: -200px;
    top: 0;
    height: 100%;
    padding-top: 30px;
    padding-left: 20px;
    min-width: 46vw;
    width: 46%;
    gap: 5px;
    z-index: 10;
    background-color: white;
    box-shadow: rgba(0, 0, 0, .3) 0px 0px 15px;

}

.taskSide-detail::-webkit-scrollbar-track {
    background: white;
    border-top: 1px solid rgb(30, 31, 33, );
}

.taskSide-detail::-webkit-scrollbar {
    width: 1px;
    size: 1px;
    height: 1px;
    background-color: rgba(30, 31, 33, 0.3);
}

.taskSide-detail::-webkit-scrollbar-thumb:hover {
    background: rgba(30, 31, 33, 0.3);
    border-radius: 10px;
}

.taskSide-detail {

    position: fixed;
    top: 0;
    right: 0;
    overflow-y: auto;
    overflow-x: hidden;
    color: black;
    gap: 5px;
    height: 100%;
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
    min-width: 29vw;
    width: 29%;
    z-index: 10;
    background-color: white;
    box-shadow: rgba(0, 0, 0, .3) 0px 0px 15px;
}


.readSide-detail-closed {
    position: fixed;
    /* position: absolute; */
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* position: fixed; */
    color: black;
    right: -200px;
    top: 0;
    height: 100%;
    /* height: 1200px; */

    /* height:100vh; */
    padding-top: 30px;
    padding-left: 20px;
    min-width: 46vw;
    width: 46%;
    gap: 5px;
    z-index: 10;
    background-color: white;
    box-shadow: rgba(0, 0, 0, .3) 0px 0px 15px;
}

.readSide-detail-closed .close-s-div {
    margin-left: 27.5vw;
}

.readSide-deatil {
    position: fixed;
    right: 0;
    overflow-y: auto;
    color: black;
    top: 0;
    height: 100%;
    padding-top: 30px;
    padding-left: 20px;
    min-width: 31vw;
    width: 31%;
    z-index: 10;
    background-color: white;
    box-shadow: rgba(0, 0, 0, .3) 0px 0px 15px;
}

.readSide-deatil::-webkit-scrollbar-track {
    /* Background */
    background: white;

    /* margin-block: 1px; */
    border-top: 1px solid rgb(30, 31, 33, );
}

.readSide-deatil::-webkit-scrollbar {
    width: 1px;
    size: 1px;
    height: 1px;
    background-color: rgba(30, 31, 33, 0.3);
}

.readSide-deatil::-webkit-scrollbar-thumb:hover {
    background: rgba(30, 31, 33, 0.3);
    border-radius: 10px;
}


.s-complete button {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    width: 160px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid rgb(237, 234, 233);
    box-shadow: rgba(0, 0, 0, .2) 0px 0px 5px;
    background-color: white;
}

.s-complete {
    width: 160px;
    height: 40px;
    margin-bottom: 30px;
}

.s-complete:hover button {
    background-color: rgb(224, 244, 236);
    color: rgb(31, 121, 87);
    border: 2px solid rgb(31, 121, 87);
}

.close-s-div {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    position: absolute;
    font-size: 18px;
    margin-left: 25.5vw;
    top: 100px;

}

.close-s-div #tip-text {
    visibility: hidden;
    z-index: 100;
    background-color: rgb(37, 38, 40);
    color: white;
    padding: 1px;
    line-height: 1.5;
    border-radius: 6px;
    width: 80px;
    text-align: center;
    border-radius: 6px;
    width: 80px;
    height: 18px;
    position: absolute;
    left: 50%;
    top: 120%;
    margin-left: -40px;
    font-size: 12px;


}

.close-s-div:hover {
    cursor: pointer;
    /* background-color: rgb(249,248,248); */

}

.icon-exit {
    display: inline-block;
    line-height: 40px;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    text-align: center;
}

.icon-exit:hover {
    background-color: rgb(249, 248, 248);
}

.close-s-div:hover #tip-text {
    visibility: visible;
}

.close-s-div #tip-text::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
}

.s-complete-button {
    color: black;
}

.s-complete-button-completed {
    color: green;
}

.s-complete {
    margin-top: 70px;
}

.s-task-title {
    width: 99%;
    border: none;
    font-size: 20px;
    font-weight: 600;
    height: 50px;
    margin-bottom: 20px;
    margin-top: 10px;

}


.s-task-title-closed {
    width: 65.5%;
    border: none;
    font-size: 20px;
    font-weight: 600;
    height: 50px;
    margin-bottom: 20px;
    margin-top: 10px;
}

.t-s-error-list {
    position: absolute;
    display: flex;
    flex-direction: column;
    color: rgb(240, 106, 106);
    font-size: 12px;
    /* height: 20px; */
    /* background-color: aqua; */
    align-items: flex-start;

    /* justify-content: flex-start; */
    width: 300px;
    /* margin-top: -60px; */
    height: 30px;
    /* margin-left: 10px; */
    margin-left: -40px;
    top: 60px;

    /* margin-left: 20px; */
}

.assignee-s-d {
    display: flex;
    flex-direction: row;
    padding: 5px;
    gap: 15%;
    justify-content: flex-start;
    align-items: center;
    width: 120%;
    height: 50px;
    font-size: 16px;
    color: black;
}

.assignee-s-d:hover {
    border-radius: 8px;
    background-color: rgb(249, 248, 248);
    cursor: pointer;

}

.s-date-setting {
    display: flex;
    flex-direction: row;
    padding: 5px;
    gap: 15%;
    justify-content: flex-start;
    align-items: center;
    width: 120%;
    height: 50px;
    font-size: 16px;
    color: black;
}

.s-date-setting:hover {
    border-radius: 8px;
    background-color: rgb(249, 248, 248);
    cursor: pointer;
}

.s-date-div {
    display: flex;
    flex-direction: row-reverse;
    gap: 30px;

}

.s-t-c-title {
    /* margin-left: -10px; */
    margin-top: -15px;
}

.s-calendar {
    z-index: 1000;
    position: relative;
}

.s-date-open {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* background-color: aqua; */
    /* margin-top: 300px; */
    /* width: 150px;
    height: 60px; */
    margin-left: -130px;
    width: 95px;
    height: 20px;
}

.s-calendar-icon {
    margin-left: 6vw;
    margin-top: 15px;

}

.t-s-p {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    padding: 5px;
    gap: 15.5%;
    justify-content: flex-start;
    align-items: center;
    width: 120%;
    height: 50px;
    font-size: 16px;
    color: black;
}

.t-s-p:hover {
    border-radius: 8px;
    background-color: rgb(249, 248, 248);
    /* cursor: pointer; */
}

.single-project-icon2 {

    width: 40px;
    height: 40px;
    border-radius: 10px;
}

.t-p-t {
    margin-left: -7%;
}

.s-priority {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    padding: 5px;
    gap: 16%;
    justify-content: flex-start;
    align-items: center;
    width: 120%;
    height: 50px;
    font-size: 16px;
    color: black;
}

.s-priority:hover {
    border-radius: 8px;
    background-color: rgb(249, 248, 248);
    cursor: pointer;
}

.s-p-content select {
    padding: 10px;
    margin-left: 10px;
    width: 265px;
    height: 40px;
    border-radius: 5px;
    font-size: 16px;
}

.s-status {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    padding: 5px;
    gap: 16%;
    justify-content: flex-start;
    align-items: center;
    width: 120%;
    height: 50px;
    font-size: 16px;
    color: black;
}

.s-status:hover {
    border-radius: 8px;
    background-color: rgb(249, 248, 248);
    cursor: pointer;
}

.s-s-labels select {
    padding: 10px;
    margin-left: 16px;
    width: 265px;
    height: 40px;
    border-radius: 5px;
    font-size: 16px;
}

.side-description {
    margin-bottom: 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: fit-content;
    min-width: 100%;
    margin-left: 5px;
    color: black;
}

.s-d-title {
    font-size: 16px;
    color: black;
}

.edit-s-description {
    height: fit-content;
    width: fit-content;
    min-height: 80px;
    min-width: 115%;
    overflow-y: auto;
    resize: none;
    border-radius: 3px;
    /* background-color: aqua; */
    /* margin-left: 20px; */
}

.edit-s-description:hover {
    cursor: pointer;
}

.edit-s-description::-webkit-scrollbar-track {
    /* Background */
    background: white;

    /* margin-block: 1px; */
    border-top: 1px solid rgb(30, 31, 33, );
}

.edit-s-description::-webkit-scrollbar {
    width: 1px;
    size: 1px;
    height: 1px;
    background-color: rgba(30, 31, 33, 0.3);
}

.edit-s-description::-webkit-scrollbar-thumb:hover {
    background: rgba(30, 31, 33, 0.3);
    border-radius: 10px;
}

.edit-s-description-closed {
    height: fit-content;
    width: fit-content;
    min-height: 80px;
    min-width: 115%;
    overflow-y: auto;
    resize: none;
    border-radius: 3px;
}

.edit-s-description-closed:hover {
    cursor: pointer;
}

.s-owner-info {
    /* margin-top: 10px; */
    display: flex;
    flex-direction: row;
    padding: 10px;
    /* gap: 16%; */
    justify-content: flex-start;
    align-items: center;
    width: 120%;
    height: 40px;
    font-size: 16px;
    gap: 10px;
    margin-left: -10px;

    color: black;
}

.s-owner-info:hover {
    border-radius: 8px;
    background-color: rgb(249, 248, 248);
}

/* .s-o-name {
    margin-left: 10px;
} */

.s-r-task-input {
    margin-top: 20px;
    border: none;
    font-size: 16px;
    font-weight: 600;
    cursor: default;
    margin-bottom: 20px;
}

.s-r-task-input:hover {
    cursor: default;
    border: none;
    outline: none
}

.s-r-task-input:focus {
    cursor: default;
    border: none;
    outline: none
}

.s-completed-2 {
    margin-top: 75px;
}

.s-completed-2 span {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    width: 60px;
    height: 30px;
    border-radius: 8px;
    border: 1px solid rgb(237, 234, 233);
    /* box-shadow: rgba(0, 0, 0, .2) 0px 0px 5px; */
    /* background-color: white; */
}

.s-task-title:hover {
    border: none;
    background-color: rgb(249, 248, 248)
}

#s-task-t:focus {

    border-radius: 8px;
    border: 2px solid rgb(81, 135, 248);

}

.s-completed-2:hover span {
    cursor: default;
    /* background-color:none; */
    /* background-color:red; */
    color: black;
}

.s-complete-button-completed2 {
    color: green;
}

.assignee-s-d-r {
    display: flex;
    flex-direction: row;
    padding: 5px;
    gap: 15%;
    justify-content: flex-start;
    align-items: center;
    width: 96%;
    height: 50px;
    font-size: 16px;
    cursor: default;
}

.assignee-s-d-r input {
    display: none;
    cursor:default;
}
.assignee-s-d-r .css-tj5bde-Svg {
    cursor:default;
}
.assignee-s-d-r .css-1fdsijx-ValueContainer {
    cursor: default;
}
.s-r-select {
    cursor: default;
}

.s-r-dueDate {
    display: flex;
    flex-direction: row;
    padding: 5px;
    gap: 15%;
    justify-content: flex-start;
    align-items: center;
    width: 95%;
    height: 50px;
    font-size: 16px;
}

.s-r-p {
    cursor: default;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 60px;
    margin-left: 8px;
    margin-top: 10px;
    margin-bottom: 40px;
}

#r-side-p {
    display: inline-block;
    padding: 10px;
    margin-left: 16px;
    width: 251px;
    height: 20px;
    border-radius: 5px;

}

.readSide-detail-closed #r-side-p {
    margin-left: 2.5vw;
}

#r-side-p:hover {
    cursor: default;
}

#r-side-s {
    display: inline-block;
    padding: 10px;
    margin-left: 22px;
    width: 251px;
    height: 20px;
    border-radius: 5px;
    cursor: default;
}

.readSide-detail-closed #r-side-s {
    margin-left: 3vw;
}

.t-s-d {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    word-break: break-word;
    margin-left: 10px;
    margin-bottom: 20px;
}

.t-s-d-c {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 10px;
    padding-right: 10px;
    /* padding-right: 30px; */
    /* margin-left: -5px; */
    min-width: 120px;
    width: fit-content;
    word-wrap: break-word;
    height: fit-content;
    min-height: 150px;
    border-radius: 5px;
    border: 1px solid rgba(37, 38, 40, 0.5);
    margin-bottom: 50px;
}

.readSide-detail-closed .t-s-d-c {
    padding-right: 2vw;
}

.edit-s-discription {
    resize: none;
    margin-bottom: 50px;
}

/* .side-description {
    position: relative;
    margin-bottom: 50px;
} */
.attachment-upload {
    /* width: fit-content;
    height: fit-content; */
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    /* background-color: aqua; */
}

.att-title {
    font-size: 16px;
    color: black;
    margin-top: 10px;
}


@media only screen and (min-width: 1500px) and (min-height: 950px) {


    .taskSide-detail {
        width: 24%;
        min-width: 24vw;
    }

    .close-s-div {

        margin-left: 19.5vw;


    }

    .readSide-detail-closed .close-s-div {
        margin-left: 20.5vw;
    }

    .taskSide-detail-closed {

        min-width: 35vw;
        width: 35%;


    }

    .readSide-detail-closed {
        min-width: 35vw;
        width: 35%;
        overflow-x: hidden;

    }

    .readSide-deatil {
        min-width: 24vw;
        width: 24%;
    }
}
