.main-page-content {
    /* background-color: aqua; */
    /* height: 100%; */
    /* position: fixed; */
    /* width: 100%; */
    /* padding: 20px; */
    margin-top:20px;
    margin-left: 20px;
    min-height: 1800px;
    height: 100%;
    min-width: calc(100vw - 280px);
    width:calc(100% - 280px);


}

.mainPage-open{
    min-height: 100vh;
    height: 100%;
    min-width: 1400px;
    width:100%;
    position: absolute;
    top:66px;
    left:255px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;


}


.mainPage-single-project-icon{
    border-radius: 30%;
    width: 40px;
    height: 40px;
    object-fit: cover;
}

.mainPage-closed{
    height: 100vh;
    width: 100vw;
    position: absolute;
    padding-top:10px;
    padding-bottom: 10px;
    padding-left: 49px;
    top:66px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}

/* .mainPage-project-setting{

} */

.task-table-title{
    color: rgb(65,65,65);
    font-size: 16px;
    font-weight: 300;
    display: flex;
    flex-direction: row;
    align-items: center;


    height: 50px;
    width:90vw;
    /* margin-bottom: 20px; */
    border-top:1px solid rgb(242,242,242);
    border-bottom:1px solid rgb(242,242,242);

}

.task-table-title-closed{
    color: rgb(65,65,65);
    font-size: 16px;
    font-weight: 300;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* padding:10px; */
     /* background-color: aqua; */
    height: 50px;
    min-width: 100vw;
    width:100%;
    /* margin-bottom: 20px; */
    border-top:1px solid rgb(242,242,242);
    border-bottom:1px solid rgb(242,242,242);

}

.mian-page-sectionList{
    /* display: flex;
    flex-direction: column; */

    /* height: 80px; */
    width:90vw;
    /* border-top:1px solid rgb(242,242,242);
    border-bottom:1px solid rgb(242,242,242); */

}
.main-page-sectionList-closed{
    min-width: 100vw;
    width:100%;
}

.t-t-name {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-right:1px solid rgb(242,242,242);
}

.t-t-assignee{
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-right:1px solid rgb(242,242,242);
}


.t-t-dueDate{
    width: 13%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-right:1px solid rgb(242,242,242);
}

.t-t-priority{
    width: 13%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-right:1px solid rgb(242,242,242);
}


.t-t-status{
    width: 13%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-right:1px solid rgb(242,242,242);

}
.task-assignee-column .css-13cymwt-control{
    cursor: pointer;
}
.task-assignee-column .css-13cymwt-control:hover{
    cursor: pointer;
}
.assignee-s-d-r .css-13cymwt-control {
    cursor:default;
}
.assignee-s-d .css-13cymwt-control{
    cursor: pointer;
}
.assignee-s-d-r .css-13cymwt-control .css-tj5bde-Svg {
    cursor: default;
}
.assignee-s-d-r input {

    display: none;
}
