.creat-project-container {
    display: flex;
    flex-direction: column;
    width: 480px;
    height: 470px;
    border-radius: 8px;
    background-color: rgb(242, 243, 245);
}

.creat-project-form {
    width: 100%;
    height: 90%;
    outline: none;
    overflow: hidden;
    background-color: white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.creat-project-title h1 {
    color: rgb(57, 57, 59);
    font-size: 25px;
}

.creat-project-content {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    padding-right: 10px;
    text-align: center;
}

.close-x-img2 {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.mark-logo2 {
    margin-left: 420px;
    margin-top: -40px;
}

.close-x-img2:hover {
    cursor: pointer;
    background-color: rgb(227, 229, 232);
}

#errors3-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 20px;
    width: 95%;
    font-size: 10px;
    color: rgb(240, 106, 106);
    font-weight: 500;
    margin-top: 10px;
    /* margin-bottom: 50px; */

}

.creat-project-input {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
}

.p-input-title,
.s-input-title2,
.s-input-title3 {
    color: rgb(56, 57, 58);
    font-size: 18px
}

.creat-project-input input {
    background-color: rgb(227, 229, 232);
    border: none;
    border-radius: 5px;
    color: rgb(0, 0, 0);
    width: 450px;
    height: 40px;
}

.c-p-b-homePage {
    background-color: rgb(240, 106, 106);
    color: black;
    border-radius: 8px;
    width: 130px;
    height: 40px;
    font-size: 15px;
}

.c-p-b-homePage:hover {
    width: 150px;
    font-size: 18px;
}

.create-project-button {
    margin-top: 20px;
    margin-left: 165px;
    margin-bottom: 20px;
}

#create-server-plus:hover {
    cursor: pointer;
}

#c-p-input:focus {
    border: 2px solid rgb(81, 135, 248);
    border-radius: 5px;
}
