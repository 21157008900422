.select-setting{
    display: flex;
    flex-direction: row;
    gap:10px;
    align-items: center;
    margin-bottom:20px;
    margin-top: 10px;
}

.project-title{
    font-size: 22px;
    font-weight: 600;
}
.arrow-icon {
    /* cursor: pointer; */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    /* margin-top:-160px ; */
    border-radius: 8px;
    /* background-color: aqua; */

}
 .arrow-icon i {
    position:relative;
    margin-top: -150px;
    /* top:46px;
    left:280px; */
    /* color: brown; */
 }
.arrow-icon:hover i{
   margin-top: 10px;
}
.arrow-icon:hover{
    cursor:pointer;
    background-color: rgb(245,243,243);

}
.arrow-icon span{
 visibility: hidden;
}

.arrow-icon:hover span{
    visibility: visible;
    opacity: 1;
    z-index: 100;
    background-color: rgb(37, 38, 40);
    color: white;
    text-align: center;
    padding: 3px;
    line-height: 1.5;
    border-radius: 6px;
    font-size: 0.75em;
    word-break: break-all;
    width: 100px;
    height: 18px;
    position: absolute;
    /* top:150%;
   left:50%; */
    margin-top: -28px;
    margin-left: -2px;

    text-decoration: none
}

#project-setting-title::after{
    content: "";
    display: block;
    overflow: hidden;
    margin-left: -5px;
    width: 0px;
    height: 0px;
    padding: 0;
    font-size: 0;
    border: 5px solid transparent;
    border-top-style: solid;
    border-top-color: black;
    position: absolute;
    top: 100%;
    left: 50%;
}
.project-setting-dropMenu{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    padding:5px;
    position: absolute;
    background-color:white;
    min-width: 160px;
    z-index: 1;
    top: 80px;
    width: 210px;
    height: 80px;
    border-radius: 8px;
    /* border: .2px solid rgb(211, 209, 209); */
    /* margin-left: px; */
    box-shadow: 0px 0px 16px rgba(183, 183, 183, 0.5);

}
.project-edit-div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
    /* background-color: aqua; */
    /* margin-top: -5px; */

}

.project-edit-div:hover{
    cursor: pointer;
    background-color: rgb(245,243,243);
    /* background-color: rgba(240, 106, 106,0.2); */
    border-radius: 5px;

}


.project-e-wapper {
    margin-bottom: 5px;
    height: 50%;
    border-bottom: 1px solid rgb(219, 223, 230);

}
.project-delete-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 50%;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
}
.project-delete-div:hover{
    cursor: pointer;
    background-color: rgb(245,243,243);
    /* background-color: rgba(240, 106, 106,0.2); */
    border-radius: 5px;

}
