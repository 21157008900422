 .section-in-project-container {
     /* background-color: aqua; */
     height: fit-content;
     display: flex;
     flex-direction: column;
     justify-content: flex-start;
 }

.section-part{
    /* background-color: aliceblue; */
     margin-bottom: 10px;
 }

.addSection-main-container{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left:10px;
    padding-right: 10px;
    margin-left: -10px;
    font-size: 20px;
    width: 150px;
    height: 50px;
    font-weight: 600;
}
.addSection-main-container:hover{
    border-radius: 8px;
    background-color: rgb(249,248,248);
    cursor: pointer;
}
.section-create-div{
    /* background-color: aqua; */
    width:100vw;
    height: 60px;

}
 .section-create-div input {
    border: none;
    width: 28%;
    height: 50px;
    font-size: 20px;
    border-radius: 8px;
    border: 2px solid rgb(81, 135, 248);
 }
.add-section-input:focus{
    cursor: pointer;
    border-radius: 8px;
    border: 2px solid rgb(81, 135, 248);

}
.add-section-input:hover{
    cursor: pointer;
    border-radius: 8px;
    background-color:rgb(249,248,248)
}
.s-detail-content{
    color: rgb(240, 106, 106);
    font-size: 12px;
}
