.task-assignee-logo{
    width: 30px;
    height: 30px;
    border-radius: 50%
}

.show-tasks-section2{
    display: flex;
    flex-direction: column-reverse;
    min-height:fit-content;
    width: 90vw;
    position: relative;
    /* background-color: brown; */


    align-items: flex-start;
    /* background-color: aqua; */
}

.closed-tasks-section{
    display: flex;
    flex-direction: column-reverse;
    min-height:fit-content;
    width:100vw;
    /* background-color: cadetblue; */
    /* margin-bottom: 20px; */
    /* border-top:1px solid rgb(242,242,242);
    border-bottom:1px solid rgb(242,242,242); */
}
