.title-open {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;

    font-size: 22px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 35vw;


}


.homePage-closed {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 66px;
    min-width: 100vw;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background-color: white;
    /* position: fixed; */
    overflow:auto;

}

.homePage-open{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 66px;
    left:300px;
    min-width: 100vw;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    /* position: fixed; */
    /* overflow: auto; */

    background-color: white;
    /* overflow-y: auto; */
    /* overflow-x: hidden; */
}


.title-closed {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    font-size: 22px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 20px;


}


.home-page-projectcreateModal{
    display: flex;
    flex-direction: row;
     /* background-color: aqua; */
    width: 180px;
    height: 50px;
    margin-top: 42px;
    gap:10px;
    font-size: 18px;
    align-items: center;
    padding: 10px;
    color: rgb(116,117,117);
    margin-bottom: 20px;
}

.home-page-projectcreateModal:hover{
    background-color: rgb(249,248,248);
    border-radius: 8px;
    cursor: pointer;
    color:rgb(30,31,33)
}

.create-title{
    font-size: 18px;
}
