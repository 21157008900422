.task-lists-currentUser{
    padding:20px;
    min-height: 100vh;
    height: 100%;
    min-width: calc(100vw - 280px);
    width:calc(100% - 280px);
    position: absolute;
    top:66px;
    left:280px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.task-lists-currentUser-closed {
    padding-left:170px;
    padding-top: 20px;
    height: 100vh;
    width: 100vw;
    position: absolute;
    top:66px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.tasks-title{

    /* margin-bottom: 50px; */
    position: absolute;
    top:110px;
    left:48vw;
    /* margin-bottom: 20px; */
    font-size: 28px;
    font-weight: 600;
}

.user-none-tasks{
   position: absolute;
   left:30vw;
   top:3vw;
}

.task-table-title2{
    /* margin-top:40px; */
    color: rgb(65,65,65);
    font-size: 16px;
    font-weight: 300;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* padding:10px;
    background-color: aqua; */
    height: 50px;
    width:90vw;
    margin-top: 100px;
    border-top:0.5px solid rgb(242,242,242);
    border-bottom:0.5px solid rgb(242,242,242);

}


.task-table-title-closed2{
    margin-top:100px;
    color: rgb(65,65,65);
    font-size: 16px;
    font-weight: 300;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* padding:10px; */
     /* background-color: aqua; */
    height: 50px;
    min-width: 100vw;
    width:100%;
    /* margin-bottom: 20px; */
    border-top:0.5px solid rgb(242,242,242);
    border-bottom:0.5px solid rgb(242,242,242);

}


.sigle-project-tasks-div{
    min-width: calc(100% - 280px);
    width: 100%;
   min-height: fit-content;

    /* min-height:100vw; */


}
.projec-taskList{
    display: flex;
    flex-direction: column;
}

.title-project{
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 22px;
    font-weight: 600;
    width: 100%;
    height: 100px;
    border-bottom: 0.5px solid rgb(242, 242, 242);
    border-top: 0.5px solid rgb(242, 242, 242);

}
.t-t-name2 {
    width: 30.5%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-right:1px solid rgb(242,242,242);
}
.t-t-dueDate2{
    width: 22%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-right:1px solid rgb(242,242,242);
}

.t-t-priority2{
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-right:1px solid rgb(242,242,242);
}

.t-t-status2{
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-right:1px solid rgb(242,242,242);

}

.task-title{
    width: 30%;
    height: 62px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
    border-right:1px solid rgb(242,242,242);
    /* border-bottom:0.3px solid rgb(242,242,242); */
    border-top:0.3px solid rgb(242,242,242);
    margin-top:-1px;
}

.myTask-dueDate{
    margin-left: 30.5%;
    margin-top: -63px;
    justify-content: center;
    width: 22%;
    height: 62px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-right:1px solid rgb(242,242,242);
    /* border-bottom:0.3px solid rgb(242,242,242); */
    border-top:0.3px solid rgb(242,242,242);
}
#myTask-p{
    margin-left: 52.5%;
    margin-top: -62.5px;
    justify-content: center;
    width: 20%;
    height: 62px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-right:1px solid rgb(242,242,242);
    /* border-bottom:0.3px solid rgb(242,242,242); */
    border-top:0.3px solid rgb(242,242,242);
}

#p-s-myTask{
    display: inline-block;
    width: 100px;
    height: 30px;
    line-height:30px;
    text-align: center;
    font-size: 18px;
    border-radius: 10px;
}

#myTask-s{
    margin-left: 72.5%;
    margin-top: -62.5px;
    justify-content: center;
    width: 20%;
    height: 62px;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* border-right:1px solid rgb(242,242,242); */
    /* border-bottom:0.3px solid rgb(242,242,242); */
    border-top:0.3px solid rgb(242,242,242);
}
