.h-users-logo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.collaborator-div{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 10px;
    padding-left: 30px;
    gap: 20px;
    /* overflow-x: hidden; */
    align-items: flex-start;
    width: 75vw;
    height: 35vh;
    overflow-y: auto;
    border: 2px solid rgba(237, 234, 233, 0.8);
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(237, 234, 233, 0.5);

}

.collaborator-div:hover {
    /* cursor: grab; */
    border: 1px solid rgba(175, 171, 172, 1);
    box-shadow: 0px 0px 10px rgba(175, 171, 172),

    }

.collaborator-div::-webkit-scrollbar-thumb {
    /* Foreground */

    background:rgba(248,234,234,0.3);
    border-radius: 8px;

	border: 15px solid transparent; /* 设置边距透明 */
    background-clip: padding-box; /* 设置背景范围 */
	-webkit-box-shadow: inset005pxrgba(0,0,0);

}

.collaborator-div::-webkit-scrollbar-track {
    /* Background */
    background:white;

    /* margin-block: 1px; */
    border-top: 1px solid rgb(30,31,33,);
}

.collaborator-div::-webkit-scrollbar{
    width: 1px;
    size:1px;
    height:1px;
    background-color: rgba(30,31,33, 0.3);
  }
.collaborator-div::-webkit-scrollbar-thumb:hover {
    background: rgba(30,31,33, 0.3);
    border-radius: 10px;
  }


    .h-users-title {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap:10px;
        margin-top: 10px;
        margin-bottom: 10px;
        align-items: center;
        /* justify-content: center; */
    }

    .h-u-s1 {
        font-size: 20px;
        font-weight: 600;
    }

    .h-u-s2 {
        padding-left: 10px;
        color: rgb(88, 88, 89);
        font-size: 15px;
    }

    .members-list-card {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;
        gap:20px;
        margin-top: 30px;



    }

    .h-user-s-card {
        /* position:absolte;
        top:100px; */
        width: 130px;
        height: 150px;
        border: 1px dashed rgba(215, 213, 213, 1);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 600;
    }

    .h-user-s-card:hover {
        border: 1px solid rgb(175, 171, 172);
    }
