.attachment-errors {
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: 25px;
    font-size: 12px;
    align-items: center;
    font-weight: 600;
    margin-top: -5px;
    margin-bottom: 5px;

    /* margin-top: -100px; */

}

.attachment-container {
    /* background-color: aqua; */
    width: 117%;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 50px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column-reverse;
    color: black;
}

.edit-a-from-upload {
    display: flex;
    flex-direction: column;
    gap: 15px;
    color: black;
    margin-bottom: 20px;
    /* background-color: antiquewhite; */
}

.file-a-input input{

    gap:5px;
    font-size: 14px;
    width: 70%;
    height: 45px;
    text-align: center;
    line-height: 45px;

    /* background-color: antiquewhite; */
}

.name-a-input .attachment-name-input {
    font-size: 16px;
    width: 115%;
    height: 30px;
    font-size: 14px;
    border:0.2px solid black


}

.name-a-input {
  margin-top: -10px;
  margin-bottom: 10px;

}
.attachment-name-input:focus{
    border: 2px solid rgb(81, 135, 248);
}

.upload-input:hover{
    cursor: pointer;
}
.a-upload-button button{
    margin-top: -11px;
    font-size: 14px;
    width:140px;
    height: 40px;
    border-radius: 5px;
    background-color: rgb(251,206,117);

}
.a-upload-button{
    margin-left: 75%;
    margin-top: -48px;

}
.attachment-name{
  font-size: 16px;
}

.owner-info-a {
    display: flex;
    flex-direction: row;
    gap:5px;
    align-items: center;
    height: fit-content;
    font-style: italic;

}



.upload-button::-webkit-file-upload-button {
    padding: 0;
    width: 140px;
    height: 40px;
    font-size: 14px;
    color: #0F1111;
    background:rgb(78,202,195);
    border: 1px solid #D5D9D9;
    border-radius: 5px;
    box-shadow: 0px 0px 1.5px 1.5px rgb(243, 244, 244);
    cursor: pointer;
    text-decoration: none;
    /* margin-top: 8px;
    margin-right: 8px; */
    /* font-family: 'Amazon Ember', Arial, sans-serif; */
}
.upload-button::-webkit-file-upload-button:hover {
    background: rgb(247, 250, 250);
}
