.signup-page{
    position: fixed;
    overflow-y: auto;
    height: 100vh;
    width: 100%;
    display:flex;
    flex-direction:row;
    align-items: center;
    /* padding-left: 5vw;
    padding-right: 5vw; */
    padding-left: 1vw;
    padding-right:1vw;
    /* align-items:center; */
    /* overflow-y: auto; */

    background-color: rgb(247,248,248);
}
.left-img2 img{
    width: 100%;
    object-fit:cover;
     height: 660px;
     border-radius: 8px;
     box-shadow: rgba(0,0,0,.2) 0px 0px 15px;
}
.left-img2{

   position: absolute;
    height: fit-content;
    min-height: 630px;
    width: 49%;
    margin-left: 2vw;
}

.signup-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    padding-top:10px;
    padding-bottom:25px;
    padding-left:20px;
    padding-right:20px;
    /* overflow-y: auto; */
    margin-left:58vw;
    margin-top: -0.5vh;

    /* right:10vw; */
    /* top:5vh; */
    height: fit-content;
    min-height: 600px;
    min-width: 35vw;
    width: 35%;
    border-radius: 8px;
    background-color:white ;
    box-shadow: rgba(0,0,0,.2) 0px 0px 15px;
}

.seprate-line-login2{
    margin-left:54.5vw;
    position: absolute;
    /* right:10vw; */

    width: 1px;
    height: 650px;
    background-color: rgba(111,111,111,0.2);


}

.form-title{
    margin-top: -10px;
    font-size: 25px;
    font-weight: 400;
    margin-bottom: 18px;
}
.signUp-input{
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 5px;
    gap:5px;
}
.s-u-font{
    color: rgb(56,57,58);
    font-size: 16px;
}
.s-input{

    background-color: rgb(227, 229, 232);
    border: none;
    border-radius: 5px;
    color: rgb(0,0,0);
    width: 33vw;
    height: 40px;
    font-size: 16px;
}
.signUp-page-logo{
    display: flex;
    flex-direction: row;
    gap:20px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 10px;
    /* margin-left: 40px; */
    color: black;
}

.signup-button button{
    /* /* margin-top: 10px; */
    margin-top: 20px;

    display: block;
    width: 120px;
    height: 35px;
    font-size: 16px;
    font-weight: 600;
    padding:5px;
    border-radius: 5px;
    background-color: rgb(53,54,55);
    color: white;
}

.link-login{
    margin-left:20vw;
    margin-top: -25px;
    font-size: 16px;
}


.logIn-link:hover{
 text-decoration: underline;
}
.signup-button{
    width: fit-content;
    height: fit-content;
}
#signup-button2:hover{
    background-color: rgb(240,106,106);
    color: rgb(53,54,55);
    filter: brightness(100%);
}
.errors-lists-s{
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    height: 20px;
    width: 100%;
    font-size: 12px;
    color: rgb(240, 106, 106);
    font-weight: 500;
    margin-top: -25px;
    margin-bottom: 50px;
}

#signUp button{
    font-size: 16px;
    margin-top: -35px;
    margin-left: -40px;
}
#signUp {
    width: fit-content;
    height: fit-content;
}
.signup-bottom{
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

#c-signUp-input{
    /* margin-bottom: 10px; */
}
