.single-project-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 10px;
    padding-left: 30px;
    gap:30px;
    overflow-x: hidden;
    align-items: flex-start;

    /* background-color: aqua; */
    width:75vw;
    height:35vh ;
    /* overflow: auto; */
    position: relative;
    margin-bottom: 30px;

    border: 2px solid rgba(237,234,233,0.8);
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(237, 234, 233,0.5);

}
.single-project-container:hover{
    /* cursor:grab; */
    border: 1px solid rgba(175,171,172,1);
    box-shadow: 0px 0px 10px rgba(175, 171, 172,0.5);
}



.single-project-container::-webkit-scrollbar-thumb {
    /* Foreground */

    background:rgba(248,234,234,0.3);
    border-radius: 8px;

	border: 15px solid transparent; /* 设置边距透明 */
    background-clip: padding-box; /* 设置背景范围 */
	-webkit-box-shadow: inset005pxrgba(0,0,0);

}

.single-project-container::-webkit-scrollbar-track {
    /* Background */
    background:white;

    /* margin-block: 1px; */
    border-top: 1px solid rgb(30,31,33,);
}
.single-project-container::-webkit-scrollbar{
    width: 1px;
    size:1px;
    height:1px;
    background-color: rgba(30,31,33, 0.3);
  }

  .single-project-container::-webkit-scrollbar-thumb:hover {
    background: rgba(30,31,33, 0.3);
    border-radius: 10px;
  }


.c-p-l-title{

    font-size: 20px;
    font-weight: 600;

}

.icon-lists{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap:10px;
    /* background-color: aqua; */
    margin-top: 20px;
}
.homePage-create{
    /* margin-bottom: 50px;
    background-color: aqua; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    border: 1px dotted rgb(175,171,172) ;
    border-radius: 10px;
    /* color: rgb(116,117,117); */
}

.single-project-icon{
    width: 45px;
    height: 45px;
    border-radius: 10px;
    object-fit: cover;

    /* color:rgb(116,117,117); */

}

.single-project-title2 {

    color:black;
    margin-left: 30px;
    position: relative;
    font-size: 18px;

    /* margin-left: 10px; */
}
.s-p-i-homePage{
    position: relative;
    /* margin-top: 30px; */
}
.sigle-project-homepage-div{
    display: flex;
    flex-direction: row;
    width: 180px;
    height: 50px;
    font-size: 20px;
     align-items: center;
     padding:10px;

}

.sigle-project-homepage-div:hover{
    background-color: rgb(249,248,248);
    border-radius: 8px;
    cursor: pointer;
}
