/* TODO Add site wide styles */

/* TODO Add site wide styles */

/* html{
  overflow: auto;

} */
html::-webkit-scrollbar-thumb {
    /* Foreground */

    background:rgba(248,234,234,0.3);
    border-radius: 8px;

	border: 15px solid transparent; /* 设置边距透明 */
    background-clip: padding-box; /* 设置背景范围 */
	-webkit-box-shadow: inset005pxrgba(0,0,0);

}

html::-webkit-scrollbar-track {
    /* Background */
    background:white;

    /* margin-block: 1px; */
    border-top: 1px solid rgb(30,31,33,);
}
html::-webkit-scrollbar{
    width: 1px;
    size:1px;
    height:1px;
    background-color: rgba(30,31,33, 0.3);
  }

html::-webkit-scrollbar-thumb:hover {
    background: rgba(30,31,33, 0.3);
    border-radius: 10px;
  }

html {
    overflow: auto;
}

html,
body {
    font-family: "Proxima Nova";
    box-sizing: border-box;
    margin: 0;
    word-wrap: break-word;
    word-break: break-word;
    font-size: 16px;
    min-height: 100vh;
    min-width: 100vw;
    color: black;



    /* background-color: rgb(30,31,33); */
}

select , option {
    font-size: 18px;
}

ul,
li {
    list-style-type: none;
}

button,
input:hover {
    cursor: pointer;
}
textarea:hover{
    cursor: pointer;
}
a {
   text-decoration: none;
    color: rgb(240, 106, 106)
}
a:hover {

    cursor: pointer;
    text-decoration: none;
}

button,input:focus {
    outline: none;
}

button {
    border: none;
}

button:hover {
    filter: brightness(90%)
}

input,textarea {
    font-size: 16px;
    border-radius: 5px;
    font-family: "Proxima Nova";
    word-wrap: break-word;
}

input:focus{
    border: 2px solid rgb(81, 135, 248);
}
textarea:focus {
    border: 2px solid rgb(81, 135, 248);
}


