.login-page{

    /* overflow-y: auto; */
    margin: 0;
    min-height:600px;
    height: 100vh;
    min-width: 100vw;
    width: 100%;
    display:flex;
    flex-direction:row;
    padding-left: 5vw;
    padding-right: 5vw;
    /* justify-content: center; */
    align-items:center;

     background-color: rgb(247,248,248);
}
.left-img img{
    width: 100%;
    object-fit:cover;
     height: 500px;
     border-radius: 8px;
     box-shadow: rgba(0,0,0,.5) 0px 0px 15px;
}
.left-img{
    /* top:7.4vh; */
    position: absolute;
    height: fit-content;
    min-height: 500px;
    width: 45%;
    margin-left: vw;
}

.login-form-right{
    margin-top: -10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top:10px;
    padding-bottom: 10px;
    padding-left:5vw;
    padding-right: 5vw;
    margin-left:58vw;
    position: absolute;
    /* right:10vw; */
    /* top:7vh; */
    height: fit-content;
    height: 480px;
    min-width: 22vw;
    width: 22%;
    border-radius: 8px;
    background-color:white ;
    box-shadow: rgba(0,0,0,.2) 0px 0px 15px;
}
.seprate-line-login{
    margin-left:51.5vw;
    position: absolute;
    /* right:10vw; */
    /* top:8vh; */
    width: 1px;
    height: 500px;
    background-color: rgba(111,111,111,0.2);


}
.login-page-logo{
    display: flex;
    flex-direction: row;
    gap:20px;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 10px;
    /* margin-left: 40px; */
    color: black;
}
.logo-img-2{
    width: 30px;
    height: 30px;
}

.welcome-title{
   font-size: 30px;
   font-weight: 400;
   margin-bottom: 5px;
}

.errors-lists-login{
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    height: 25px;
    width: 100%;
    font-size: 12px;
    color: rgb(240, 106, 106);
    font-weight: 500;
    /* margin-top: -5px; */
    margin-bottom: 10px;

}

.login-e-input{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* margin-left: -130px; */
    margin-bottom: 20px;
    gap:20px;
}
.l-e-font{
    color: rgb(56,57,58);
    font-size: 18px;

}

.l-e-input{

background-color: rgb(227, 229, 232);
  border: none;
  border-radius: 5px;
  color: rgb(0,0,0);
  width: 28vw;
  height: 40px;
  font-size: 18px;

}

.l-e-input:focus{
    border: 2px solid rgb(81, 135, 248);
    border-radius: 5px;
}
.login-button > #home-signUp{
    display: block;
    width: 110px;
    height: 35px;
    font-size: 18px;
    font-weight: 600;
    padding:5px;
    border-radius: 5px;
    background-color: rgb(53,54,55);
    color: white;

}

/* .login-button{
  /* margin-left: -130px; */
  /* margin-top: 40px; */
/* }  */
.Demo-button button{
    display: block;
    width: 120px;
    height: 35px;
    font-size: 18px;
    font-weight: 600;
    padding:5px;
    border-radius: 5px;
    background-color: rgb(53,54,55);
    color: white;
}
.demo-user-login-button:hover{
    background-color: rgb(240,106,106);
    color: rgb(53,54,55);
    filter: brightness(100%);
}
/* .Demo-button{
  margin-left:100%;
  margin-top: -40px;
} */
.login-button-demo{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.signUp-link{
    display: flex;
    flex-direction: row;
    gap:20px;
    margin-top: 40px;
    font-size: 18px;
    margin-left: -20px;
}
.signUp-link #login-signUp-link:hover{
  text-decoration: underline;
}
.login-demo-div{
  width: 28vw;
  height: fit-content;
 margin-top:30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* gap:20px; */
  /* justify-content: space-between; */
  /* align-items: flex-start; */
}
