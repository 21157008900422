.sideBar-top-container {
    background-color: rgb(37, 38, 40);
    height: 65px;
    min-width: 100vw;
    width: 100%;
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    gap: 30px;
    position: fixed;
    top: 0;
    z-index: 999;
    border-bottom: 1px solid rgba(162, 160, 162, 0.5);
}

.sideBar-top-left {
    display: flex;
    position: absolute;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    left: 2vw;
    width: 214px;
}

.profile-logo {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-top: -1px;

}

.sideBar-profile-logo-r {
    position: absolute;
    display: flex;
    position: absolute;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 30px;
    right:2vw;
    width: 214px;
}

.home-l-t {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

}

.home-title {
    color: white;
    font-size: 22px;
}

.sideBar-open-icon {
    color: white;
    font-size: 25px;
}

.home-logo {
    width: 35px;
    height: 35px;
}

.currentUser-profile img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
}


.sidebar-open {

    top: 66px;
    position: fixed;
    /* top:0; */
    display: flex;
    z-index: 1000;
    /* flex: 0 0 auto; */
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: column;
    /* justify-content: space-between;  */
    background-color: rgb(30, 31, 33);
    color: white;
    transition: margin-left 250ms ease-out, transform 250ms ease-out;
    width: 240px;
    min-height: 100vh;
    height: 100%;
    /* overflow-y: auto; */
}

/* .sidebar-open::-webkit-scrollbar {
    width: 0.4px;
    size: 0.4px;
}

.sidebar-open::-webkit-scrollbar-thumb {
    /* Foreground */
/* background: rgb(32, 34, 37);
    border-radius: 8px; */
/* } */

/* .sidebar-open::-webkit-scrollbar-track {
    /* Background */
/* background: rgb(46, 51, 56);
    border-radius: 8px;
    margin-block: 3px;
    border-bottom: 2px solid rgb(54, 57, 63); */
/* } */


.sidebar-closed {
    margin-left: -240px;
    background-color: rgb(30, 31, 33);
    color: white;
    display: flex;
    /* flex: 0 0 auto; */
    flex-direction: column;
    transition: margin-left 250ms ease-out, transform 250ms ease-out;
    width: 240px;
    min-height: 100vh;
    height: 100%;


}

.sideBar-open-icon:hover {
    cursor: pointer;
}

.sideBar-Middle {

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* padding-left: 10px; */
    height: 200px;
    padding-top: 20px;
    gap: 10px;
}

.c-project-list::-webkit-scrollbar {
    width: 0.4px;
    size: 0.4px;
}

.c-project-list::-webkit-scrollbar-thumb {
    /* Foreground */
    background: rgb(32, 34, 37);
    border-radius: 8px;
}

.c-project-list::-webkit-scrollbar-track {
    /* Background */
    background: rgb(46, 51, 56);
    border-radius: 8px;
    margin-block: 3px;
    border-bottom: 2px solid rgb(54, 57, 63);
}

.sideBar-bottom {
    height: 150px;
    /* margin-top:-5vh; */
}

.sideBar-home {
    font-size: 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    color: rgb(232, 231, 230);
    width: 220px;
    height: 50px;
    padding-left: 10px;
    align-items: center;
    /* margin-left: -10px; */
    /* background-color: aqua; */
}

.sideBar-home:hover {
    background-color: rgb(43, 44, 46);
    border-radius: 8px;
}

.sideBar-mytask {
    font-size: 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    color: rgb(232, 231, 230);
    width: 220px;
    height: 50px;
    padding-left: 10px;
    align-items: center;
}

.sideBar-mytask:hover {
    background-color: rgb(43, 44, 46);
    border-radius: 8px;
}

.create-project {
    font-size: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    color: rgb(232, 231, 230);
    width: 220px;
    height: 50px;
    padding-left: 10px;
    gap: 30px;
    align-items: center;
}

.create-project:hover {
    background-color: rgb(43, 44, 46);
    border-radius: 8px;
}

.c-project-list {
    margin-top: -15px;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 240px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow-y: auto;
    overflow-x: hidden;

    height: calc(100vh - 465px);
    /* height: 50%;
    top:240px;
    position:absolute; */
    margin-bottom: 20px;
    border-bottom: 0.3px solid rgb(245, 248, 248);
    border-top: 0.3px solid rgb(245, 248, 248);

}

.p-title-sideBar {
    color: rgb(232, 231, 230);
}


.sideBar-myproject {
    font-size: 18px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 230px;
    height: fit-content;
    padding-left: 10px;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;


}

.sideBar-myproject:hover {
    background-color: rgb(43, 44, 46);
    border-radius: 8px;
}

.p-c-sideBar {
    width: 25px;
    height: 25px;
    border-radius: 8px;
}

.log-out-button {
    border: none;
    outline: none;
    font-size: 18px;
    background-color: rgb(240, 106, 106);
    color: black;
    border-radius: 5px;
    width: 120px;
    height: 40px;
}

.log-out-button:hover {
    width: 140px;
    font-size: 20px;
}

.sideBar-button {
    /* position: absolute;
bottom:18vh; */
    margin-left: 10px;
    margin-bottom: 20px;
}

.about-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* position: absolute;
    bottom:10vh; */
    gap: 5px;
    margin-left: 10px;
    margin-bottom: 10px;

}

.about-title {
    font-size: 18px;

}

.logo-gitHub {
    margin-top: 10px;
}

.linkedin-link {
    /* margin-left: 200px; */
    margin-left: 140px;
    margin-top: -40px;
}

.linkedin-link2 {
    /* margin-left: 200px; */
    margin-left: 140px;
    margin-top: -41px;
}
