.section-error-list {
    color: rgb(240, 106, 106);
    font-size: 12px;
    /* height: 20px; */
    width: fit-content;

}

.section-part-input {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100px;
    border-bottom: 0.5px solid rgb(242, 242, 242);
    border-top: 0.5px solid rgb(242, 242, 242);


}

/* .section-part-input:hover{
    opacity: 1;
    /* cursor: grab; }*/


#edit-section-input {
    font-size: 20px;
    font-weight: 600;
    min-width: fit-content;
    min-height: 50px;
    border: none;
    width: 20%;
    margin-top: 30px;

}
#edit-section-input2{
    font-size: 20px;
    font-weight: 600;
    min-width: fit-content;
    min-height: 50px;
    border: none;
    width: 23%;
    margin-top: 30px;
}

#edit-section-input:hover {
    cursor: pointer;


}
#edit-section-input2:hover {
    cursor: pointer;


}
#edit-section-input:focus {
    border: 2px solid rgb(81, 135, 248);
    border-radius: 10px;
    width: 20%;

    /* height: 80px; */
    /* background-color: aqua; */

}
#edit-section-input2:focus {
    border: 2px solid rgb(81, 135, 248);
    border-radius: 10px;
    width: 23%;


}

.setting-add-task {

    display: flex;
    flex-direction: row;
     margin-left: 26%;
     gap: 10px;
    font-size: 18px;
    min-width: fit-content;
    margin-left: 19.5%;
    width: 80%;
    height:100px;
    padding:30px;
    /* cursor: grab; */
    /* opacity: 0; */
    margin-top: -75px;
    /* background-color: black; */
}
.setting-add-task:hover {
  /* cursor: grab; */
   opacity: 1;
   /* background-color: aqua; */
}

/* .add-task-in-section-icon2{
    display: flex;
    flex-direction: row;
     margin-left: 26%;
     gap: 10px;
    font-size: 20px;
    min-width: fit-content;
    margin-left: 19.5%;
    width: 80%;
    height:100px;
    padding:30px;
    /* cursor: grab; */
     /* opacity: 1;
    margin-top: -75px;
} */


 .setting-add-task2 {
    display: flex;
    flex-direction: row;
    margin-left: 24.2%;
    gap: 10px;
    font-size: 18px;
    min-width: fit-content;
    width: 75%;
    height: 98px;
    margin-top: -45px;
}

.setting-add-task2:hover {
    /* cursor: grab; */
     opacity: 1;

}
/* .setting-add-task2 > #edit-section-input:focus {
    border-radius: 10px;
    width: 30%;
} */
.add-task-in-section-icon {
    width: 35px;
    height: 30px;
    line-height: 30px;
    /* padding-top: 5px; */
    border-radius: 5px;
    text-align: center;


    /* margin-top: -40px; */

    cursor: pointer;
}

.add-task-in-section-icon:hover {
    cursor: pointer;
    opacity: 1;
    background-color: rgb(249, 248, 248);

}

.open-section-setting {
    width: 35px;
    height: 30px;
    line-height: 30px;
    border-radius: 5px;
    text-align: center;

    /* background-color: azure;  */
    /* margin-top: -40px; */

}

/* .open-section-setting:target{
    opacity: 1;
/* } */
/* .open-section-setting2{
    width: 35px;
    height: 30px;
    padding-top: 5px;
    border-radius: 5px;
    text-align: center;
    opacity: 1;
}
.open-section-setting2:hover{
    background-color: rgb(249, 248, 248);
    cursor: pointer;
} */
.open-section-setting:hover {
    /* cursor: pointer; */
    opacity: 1;
    background-color: rgb(249, 248, 248);
    cursor: pointer;

}

.section-setting-dropMenu-closed {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    padding: 5px;
    /* position: absolute; */
    background-color: white;
    min-width: 160px;
    z-index: 99;
    margin-top: -50px;
    margin-left: 30%;
    width: 210px;
    height: 40px;
    border-radius: 8px;
    font-size: 16px;
    /* border: .2px solid rgb(211, 209, 209); */
    /* margin-left: px; */
    box-shadow: 0px 0px 16px rgba(183, 183, 183, 0.5);
}

.section-setting-dropMenu {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    padding: 5px;
    /* position: absolute; */
    background-color: white;
    min-width: 160px;
    z-index: 99;
    margin-top: -50px;
    margin-left: 30.5%;
    width: 210px;
    height: 40px;
    border-radius: 8px;
    font-size: 16px;
    /* border: .2px solid rgb(211, 209, 209); */
    /* margin-left: px; */
    box-shadow: 0px 0px 16px rgba(183, 183, 183, 0.5);
}

.section-edit-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: -2px;
}

.section-edit-div:hover {
    cursor: pointer;
    background-color: rgb(245, 243, 243);
    /* background-color: rgba(240, 106, 106,0.2); */
    border-radius: 5px;
    margin-top: -3px;

}

.section-delete-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
}

.section-delete-div:hover {
    cursor: pointer;
    background-color: rgb(245, 243, 243);
    /* background-color: rgba(240, 106, 106,0.2); */
    border-radius: 5px
}



.add-task-in-section-icon #c-info-title {
    visibility: hidden;

}

.add-task-in-section-icon:hover #c-info-title {
    visibility: visible;
    opacity: 1;
    z-index: 100;
    background-color: rgb(37, 38, 40);
    color: white;
    text-align: center;
    padding: 3px;
    line-height: 1.2;
    border-radius: 6px;
    font-size: 0.75em;
    word-break: break-all;
    width: 170px;
    height: 18px;
    position: absolute;
    /* top:150%;
   left:50%; */
    margin-top: -35px;
    margin-left: -96px;

    text-decoration: none
}

.add-task-in-section-icon #c-info-title::after {
    content: "";
    display: block;
    overflow: hidden;
    margin-left: -5px;
    width: 0px;
    height: 0px;
    padding: 0;
    font-size: 0;
    border: 5px solid transparent;
    border-top-style: solid;
    border-top-color: black;
    position: absolute;
    top: 100%;
    left: 50%;

}

.open-section-setting #s-info-title {
    visibility: hidden;

}

.open-section-setting:hover #s-info-title {
    visibility: visible;
    opacity: 1;
    z-index: 100;
    background-color: rgb(37, 38, 40);
    color: white;
    text-align: center;
    padding: 3px;
    line-height: 1.2;
    border-radius: 6px;
    font-size: 0.75em;
    word-break: break-all;
    width: 100px;
    height: 18px;
    position: absolute;
    /* top:150%;
       left:50%; */
    margin-top: -35px;
    margin-left: -62px;
    /* margin-left:-96px; */

    text-decoration: none
}

.open-section-setting #s-info-title::after {
    content: "";
    display: block;
    overflow: hidden;
    margin-left: -5px;
    width: 0px;
    height: 0px;
    padding: 0;
    font-size: 0;
    border: 5px solid transparent;
    border-top-style: solid;
    border-top-color: black;
    position: absolute;
    top: 100%;
    left: 50%;

}
.read-section-input{
    font-size: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100px;
    border-bottom: 0.5px solid rgb(242, 242, 242);
    border-top: 0.5px solid rgb(242, 242, 242);
}
.read-section-input input{
    font-size: 20px;
    font-weight: 600;
    min-width: fit-content;
    min-height: 50px;
    border: none;
    width: 20%;
    margin-top: 30px;

}
