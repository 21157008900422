.single-member-container {
    overflow: auto;
    word-break: break-all;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    position: absolute;
    background-color: white;
    min-width: 180px;
    padding-left: 5px;
    z-index: 1;
    top: 45px;
    font-size: 16px;
    width: 180px;
    height: 120px;
    border-radius: 10px;
    color: rgb(9, 9, 9);
    right: 5%;
    border-top: 4px solid transparent;
    padding-top: 10px;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to right, rgb(248, 249, 249), rgb(255, 255, 255)), linear-gradient(90deg, #8F41E9, #578AEF);
    box-shadow: -5px 5px 10px -4px rgba(154, 153, 153, 0.5), 5px 5px 5px -4px rgba(154, 153, 153, 0.5);
    ;

}


.single-member-container::-webkit-scrollbar-thumb {
    /* Foreground */

    background:rgba(248,234,234,0.3);
    border-radius: 8px;

	border: 15px solid transparent;
    background-clip: padding-box;
	-webkit-box-shadow: inset005pxrgba(0,0,0);

}

.single-member-container::-webkit-scrollbar-track {
    /* Background */
    background:white;

    /* margin-block: 1px; */
    border-top: 1px solid rgb(30,31,33,);
}
.single-member-container::-webkit-scrollbar{
    width: 1px;
    size:1px;
    height:1px;
    background-color: rgba(30,31,33, 0.3);
  }

.single-member-container::-webkit-scrollbar-thumb:hover {
    background: rgba(30,31,33, 0.3);
    border-radius: 10px;
  }


.c-user-profile {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 15px;

}

.c-u-p-name {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.currentUser-name {
    margin-top: 10px;
    margin-left: 50px;
}


.members-list-container{
    display: flex;
    flex-direction: row;
    gap:5px;
}

.user-logo{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.sideBar-user-logo:hover{
 cursor: pointer;
}
.members-list-title{
    font-size: 20px;
    /* margin-left: 10px; */
    margin-bottom: 10px;
}

.members-list-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap:10px;
    margin-bottom: 20px;
}

.member-container{
    display: flex;
    flex-direction: row;
    overflow: auto;
    /* gap:10px; */
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: white;
    z-index: 10;
    box-shadow: 0px 0px 10px rgba(154, 153, 153, 0.5), 5px 5px 5px -4px rgba(154, 153, 153, 0.5);
    min-width: 170px;
    height: 60px;
    border-radius: 10px;
    font-size: 16px;
    top:180px;
    margin-left: -50px;
    /* padding: 10px; */
}

.u-p-name{
    display: flex;
    flex-direction: column;
    gap:5px;

}

.mainPage-user-logo:hover{
    cursor: pointer;
}
